import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { Usuario } from "../../../core/models/model";
import { AuthService } from "../../../seguranca/auth.service";
import { UsuarioService } from "../../../usuario/usuario.service";
import { ConfirmationService } from "primeng/primeng";
import { LogoutService } from "../../../../app/seguranca/logout.service";

// Variable in assets/js/scripts.js file
declare var AdminLTE: any;

@Component({
  selector: "app-usuario-view",
  templateUrl: "./usuario-view.component.html"
})
export class UsuarioViewComponent implements OnInit {

  usuario = new Usuario();


  entidade;
  tipoEntidade;

  listaEntidades: any[];

  nomeEntidadePesquisa;
  listaEntidadesTitulo;

  entidadesDoConcedente: any;
  perfis = [];

  passwordAlso;
  password;
  confirmPassword;

  constructor(
    public auth: AuthService,
    private usuarioService: UsuarioService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private logoutService: LogoutService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.smooth("headerUsuarioView")

    this.carregarPessoa(this.auth.jwtPayload.id);
    this.title.setTitle("Visualizando usuário");

    this.selecionarEntidade();
  }

  selecionarEntidade() {
    this.entidade = null
  }

  carregarPessoa(codigo: number) {
    this.usuarioService
      .buscarPorCodigo(codigo)
      .then(pessoa => {
        this.usuario = pessoa;
        // console.log(this.usuario)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizar() {
    if (this.password === this.confirmPassword) {
      this.usuario.senha = this.password;
      this.usuario.senha_antiga = this.passwordAlso;
      this.usuarioService
        .atualizar(this.usuario)
        .then(retorno => {
          this.usuario = retorno;
          this.toasty.success("Alteração realizada com sucesso!");
          this.router.navigate(["/home"]);
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.errorHandler.handle("Senhas não conferem.");
    }
  }

  atualizarSenha() {
    if (this.password === this.confirmPassword) {
      this.usuario.senha = this.password;
      this.usuario.senha_antiga = this.passwordAlso;
      this.usuarioService
        .atualizarSenha(this.usuario)
        .then(() => {
          // this.usuario = pessoa;
          this.toasty.success("Senha alterada com sucesso!");
          this.router.navigate(["/home"]);
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.errorHandler.handle("Senhas não conferem.");
    }
  }

  confirmarExclusaoConta() {
    this.confirmation.confirm({
      message: '<b> Tem certeza que deseja excluir conta de usuário? </b>',
      accept: () => {
        this.excluirConta();
      },
    });
  }

  excluirConta() {
      this.usuarioService
        .excluir(this.usuario.id)
        .then(() => {
          this.toasty.success("Conta excluida com sucesso!");
          this.logout();
        })
        .catch(erro => this.errorHandler.handle(erro));
  }

  logout() {
    this.logoutService.logout()
      .then(() => {
        this.router.navigate(['/login']);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  smooth(elementId: string) {
    if (elementId != null) {
      setTimeout(
        function () {
          document.getElementById(elementId).scrollIntoView({
            behavior: "smooth"
          });
        }.bind(this),
        1
      );
    }
  }

}
